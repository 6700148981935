body {
  margin: 0;
  padding: 0;
}

.quill{
  background-color: #f4f4f4;
  border-radius: 0.875rem;
  padding: 0.4688rem;
}

.quill .ql-toolbar{
  border: none !important;
}
.quill .ql-container{
  min-height: 25rem !important;
  border: none !important;
  font-family: "Poppins", sans-serif !important;
}

#cookiescript_badge{
  display: none !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
  z-index: 99999 !important;
}

.Toastify__toast-container {
  z-index: 999999 !important;
}

/*Toastify__toast-container--bottom-right*/


@media(max-width: 975px) {
  .ReactModal__Overlay {
    z-index: 1000;
    height: 100%;
  }
}